import React, { useState, useEffect } from 'react';
import { Card, Typography, Switch, Modal, Tooltip, message } from 'antd';
import { SettingOutlined, EditOutlined, UploadOutlined, InfoCircleOutlined, CrownOutlined, LockOutlined,  UpCircleOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import './cardstyle.css';
import LottieAnimation from './LottieAnimation';
import SettingsPage2 from './SettingsPage2';
import TranslationSettingsPage from './Settingstranslation';
import Etrade from './etrade';
import Mobile from './mobile';
import Landingpages from './Landingpages';
import AISalesPersonalisation from './AIsalespersonalisation';
import AILandingPageOptimization from './AILandingPageOptimization';
import AITrafficRouting from './AITrafficRouting';
import { sendFeatureStatusToServer2, fetchSettingsFromServer } from './utils.js';
import { fetchUsageStats } from './utils'; // Adjust the import path as necessary

const { Text } = Typography;

const URLDataCard = ({ url, onSwitchToVariantEdit }) => {
    const defaultSettings = {
        AiABTestingEnabled: false,
        AiBasicTranslationEnabled: true,
        AdvancedTranslationEnabled: false,
        AiMobileAdaptationEnabled: false,
        ProductDescOptEnabled: false,
        LandingPagesEnabled: false,
        SmartGdprEnabled: false,
        AiSlowDown: false,
    };

    const [balance, setBalance] = useState(0); // Add balance state
    const PREMIUM_FEATURES = new Set([
        'AiABTestingEnabled',
        'ProductDescOptEnabled',
        'AdvancedTranslationEnabled',
        'LandingPagesEnabled'
    ]);

    const [settings, setSettings] = useState(defaultSettings);
    const [currentModal, setCurrentModal] = useState({ visible: false, type: null, context: {} });

    const handleSettingChange = (key, value) => {
        const updatedSettings = { ...settings, [key]: value };
        setSettings(updatedSettings);

        sendFeatureStatusToServer2({
            url: url,
            data: updatedSettings
        }).catch(error => console.error(error));


        if (value && PREMIUM_FEATURES.has(key) && balance < 1) {
            message.warning({
                content: (
                    <div style={{ fontSize: '16px', padding: '12px' }}>
                        To use PREMIUM AI models:
                        <ul style={{ margin: '8px 0 0 20px' }}>
                            <li>Switch to a paid premium plan</li>
                            <li>Add a credit card for billing</li>
                            <li>Maintain a minimum balance of $1+</li>
                        </ul>
                        <div style={{ marginTop: '12px', fontWeight: 500 }}>
                            Note: You can activate now, but functionality will be enabled after funding
                        </div>
                    </div>
                ),
                duration: 8, // 8 seconds instead of default 3
                style: { 
                    fontSize: '16px',
                    padding: '20px',
                    minWidth: '500px',
                    borderRadius: '8px'
                },
            });
        }


    };


    useEffect(() => {
        const loadBalance = async () => {
            try {
                const result = await fetchUsageStats();
                if (result?.success) {
                    setBalance(result.data.RealBalance || 0);
                }
            } catch (error) {
                console.error('Failed to load balance:', error);
            }
        };
        loadBalance();
    }, []);




    
    useEffect(() => {
        async function loadSettings() {
            try {
                const serverSettings = await fetchSettingsFromServer(url);
                if (serverSettings) {
                    setSettings({ ...defaultSettings, ...serverSettings });
                }
            } catch (error) {
                console.error('Failed to fetch settings:', error);
            }
        }
        loadSettings();
    }, [url]);

    const showModal = (type, context = {}) => {
        setCurrentModal({ visible: true, type, context });
    };

    const closeModal = () => {
        setCurrentModal(prev => ({ ...prev, visible: false }));
    };

    return (
        <Card
  bordered={false}
  className="no-shadow-card"
  style={{ width: '100%', border: 'none', boxShadow: 'none' }} // Explicitly remove border and shadow
>
            <div className="bottom-section">
                <div className="div-2">
                    {/* General AI Model Card */}
                    <div className="column ai-container">
                        <div className="ai-choice-container">
                            <div className="div-4"   >
                            <div className="premium-badge"> <UpCircleOutlined />
                    Premium AI model
                </div>

                                <div className="ai-card-content">
                                    <div className="lottie-container">
                                        <LottieAnimation 
                                          
            animationPath="https://lottie.host/a956b53c-457c-4809-be3d-282f1ec62ec6/y0VQ27Gjqk.json" 
            width={330} 
            height={250} 
          />
        </div>
        <div className="ai-text-content" data-seatextdont>
          <div className="ai-title-container" data-seatextdont>
          <h2 className="ai-title">The general AI model: boost conversions by + 35% on any website & language</h2>
</div>
<div className="ai-description">
  This AI model supercharges your website by enhancing its text. It works like a professional copywriter, creating 3–10 variants of new text for your website per batch. Then, the AI acts as a CRO performance manager, analyzing the performance of each text variation. It identifies and prioritizes the best-performing variants, presenting them to your audience more frequently for maximum impact. It can improve up to 90% of your website’s text and operates seamlessly in any language. You can see how it performs by checking the new variants in the "Variant Editor" and reviewing the improvements in "Review AI Performance" in the left menu.



          <div className="feature-enabled-text">
    <span className="checkmark-icon">✔</span> {/* Replace with an icon if needed */}
    Advanced AI Filtering enabled 
</div>
<div className="feature-enabled-text">
    <span className="checkmark-icon">✔</span> {/* Replace with an icon if needed */}
    Advanced Copywriting Enabled 
</div>


          
</div>
          <div className="ai-card-actions">
            <Switch 
              checked={settings.AiABTestingEnabled} 
              onChange={(checked) => handleSettingChange('AiABTestingEnabled', checked)} 
            />
            <div className="ai-setting-button" 
                 onClick={() => showModal('AIABTesting', {url, aiName: ''})}>
              <SettingOutlined /> Settings
            </div>
            <div className="ai-variant-button" 
                 onClick={onSwitchToVariantEdit}>
              <EditOutlined /> AI Variants
            </div>

            <a 
        href="https://seatext.com/ai-ab-testing-platform-increase-website-sales" 
        target="_blank" 
        rel="noopener noreferrer" 
        className="ai-setting-button"
    >
        <InfoCircleOutlined /> Learn more about this model
    </a>






          </div>
        </div>
      </div>
    </div>
  </div>
</div>





<div className="column ai-container">
    <div className="ai-choice-container">
        <div className="div-4"   >
            <div className="ai-card-content">
                <div className="lottie-container">
                    <LottieAnimation
                        animationPath="https://lottie.host/1c8bdd1a-3147-4402-83f8-fae01cbdb29f/2SBwuNcKM5.json"
                        width={300}
                        height={200}
                    />
                </div>
                <div className="ai-text-content">
                    <div className="ai-title-container">
                        <h2 className="ai-title"> AI for online stores: +35% more sales</h2>    <div className="premium-badge"> <UpCircleOutlined />
                    Premium AI model
                </div>

                
                    </div>
                    <div className="ai-description">
                    This AI specializes in boosting online store sales through optimized product descriptions, a practice inspired by Amazon's innovative approach to their own goods and services. Amazon has run over 12,000 A/B tests using this method, and we’ve adopted the same strategy for your store. It’s also recommended by Optimizely, which found that experimentation with product descriptions is the number one factor that can significantly improve conversion rates in e-commerce.

Using a specialized Large Language Model trained on high-converting e-commerce content, the AI generates and A/B tests multiple versions of your product descriptions, identifying the most compelling language that can potentially increase sales by up to 35%. Please note, new descriptions may be up to 30% longer, so you might need to adjust your page design to accommodate flexible text areas.


<div className="feature-enabled-text">
    <span className="checkmark-icon">✔</span> {/* Replace with an icon if needed */}
    Advanced AI Filtering Enabled
</div>
<div className="feature-enabled-text">
    <span className="checkmark-icon">✔</span> {/* Replace with an icon if needed */}
    Advanced Copywriting Enabled
</div>





</div>
               
                    <div className="ai-card-actions">
                        <Switch checked={settings.ProductDescOptEnabled} onChange={(checked) => handleSettingChange('ProductDescOptEnabled', checked)} />
                        <div className="ai-setting-button"
                            onClick={() => showModal('Product Description Optimization', {url, aiName: 'Product Description Optimization'})}
                        >
                            <SettingOutlined /> Settings
                        </div>
                        <div className="ai-variant-button"
                            onClick={onSwitchToVariantEdit}
                        >
                            <EditOutlined /> AI Variants
                        </div>

                        <a 
        href="https://seatext.com/ai-ecommerce-product-descriptions-optimization" 
        target="_blank" 
        rel="noopener noreferrer" 
        className="ai-setting-button"
    >
        <InfoCircleOutlined /> Learn more about this model
    </a>





                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</div>

<div className="div-3">
        <div className="column ai-container">
            <div className="ai-choice-container">
                <div className="div-4"   >

                <div className="free-badge">
                                    <span>Free Model</span>
                                </div>



                    <div className="ai-card-content">
                        <div className="lottie-container" style={{ transform: 'scale(0.8)', marginTop: '-20px' }}>
                            <LottieAnimation
                                animationPath="https://lottie.host/f341c635-43aa-44fb-bdcb-978f40cb8e52/aSyfdQjNP1.json"
                                width={400}
                                height={400}
                            />
                        </div>
                        <div className="ai-text-content">
                            <div className="ai-title-container">
                                <h2 className="ai-title"> Smart GDPR cookies which don't hurt conversions</h2>
                            </div>
                            <div className="ai-description">
                            Our adaptive, conversion-friendly cookie popup starts as a minimal banner, expanding after 20 seconds to encourage consent while minimizing bounce rates. With smart geo-targeting and consent history analysis, it reduces unnecessary popups while maintaining full GDPR/CCPA compliance. Compatible with major analytics platforms like Google Analytics and Microsoft Clarity, it's free forever and requires minimal setup. For advanced options, integrate with Seatext's privacy settings.
</div>
                            <div className="ai-card-actions">
                                <Switch 
                                    checked={settings.SmartGdprEnabled} 
                                    onChange={(checked) => handleSettingChange('SmartGdprEnabled', checked)} 
                                />
                                  <a 
        href="https://seatext.com/cookie-consent-solution-seatext" 
        target="_blank" 
        rel="noopener noreferrer" 
        className="ai-setting-button"
    >
        <InfoCircleOutlined /> Learn more about this feature
    </a>
                            </div>
                          
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>



























<div className="div-3">
    <div className="column ai-container">
        <div className="ai-choice-container">
            <div className="div-4"   >

            <div className="free-badge">
                                    <span>Free Model</span>
                                </div>



                <div className="ai-card-content">
                    <div className="lottie-container">
                        <LottieAnimation
                            animationPath="https://lottie.host/a0a3892d-0214-476f-9534-ca715b1a9f8a/uFmaS5zzFj.json"
                            width={400}
                            height={200}
                        />
                    </div>
                    <div className="ai-text-content">
                        <div className="ai-title-container">
                            <h2 className="ai-title">Website basic translation (without AB testing)  </h2>
                        </div>
                        <div className="ai-description">
                        Boost your sales with a multilingual website offering basic translations in 125 languages. This AI is perfect for sites with a small number of international visitors and no need for A/B testing of translations. Simply activate the model, and it will handle the rest. You can still use other website translators like Weglot or Google Translate, as this AI is designed to work alongside them without interference.
                        </div>
                        <div className="ai-card-actions">
                            <Switch checked={settings.AiBasicTranslationEnabled} onChange={(checked) => handleSettingChange('AiBasicTranslationEnabled', checked)} />
                            <div className="ai-setting-button"
                                onClick={() => showModal('Translation', {url, aiName: 'Advanced translation with A/B testing'})}
                            >
                                <SettingOutlined /> Settings
                            </div>
                            <div className="ai-variant-button"
                                onClick={onSwitchToVariantEdit}
                            >
                                <EditOutlined /> AI Variants
                            </div>


                            <Link 
        to="/welcome/image-upload" 
        className="ai-setting-button"
        style={{ marginLeft: '8px' }}
    >
        <UploadOutlined /> Upload Translated Images
    </Link>


    <a 
        href="https://seatext.com/best-website-translation-and-localization-services" 
        target="_blank" 
        rel="noopener noreferrer" 
        className="ai-setting-button"
    >
        <InfoCircleOutlined /> Learn more about this model
    </a>



                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div className="column ai-container">
        <div className="ai-choice-container">
            <div className="div-4"   >
            <Tooltip title="You can use this model only if you are on a paid plan or have a positive balance">
                <div className="premium-badge"> <UpCircleOutlined />
                    Premium AI model
                </div>
            </Tooltip>
                <div className="ai-card-content">
                    <div className="lottie-container">
                        <LottieAnimation
                            animationPath="https://lottie.host/8eb52a8d-3298-466f-9b2b-c62f0e04e193/LaIwtdYRte.json"
                            width={350}
                            height={180}
                        />
                    </div>
                    <div className="ai-text-content">
                        <div className="ai-title-container">
                            <h2 className="ai-title">Advanced AI website translation with A/B testing and AI filtering</h2>
                        </div>
                        <div className="ai-description">
                        This model introduces a new AI-driven approach to ensure the best, error-free translations for websites. Instead of one translation, it generates up to 10 variants per language and A/B tests them with real users to identify the most accurate, high-performing options. This continuous optimization makes it ideal for sites with over 1,000 international visitors daily, driving a significant increase in international sales—typically around +25%—by ensuring translations are flawless and user-friendly.
                        
                        
                        <div className="feature-enabled-text">
    <span className="checkmark-icon">✔</span> {/* Replace with an icon if needed */}
    Advanced AI Filtering Enabled
</div>
<div className="feature-enabled-text">
    <span className="checkmark-icon">✔</span> {/* Replace with an icon if needed */}
    Advanced Copywriting Enabled
</div>
                        
                        
                        
                        
                        </div>
                   
                        <div className="ai-card-actions">
                            <Switch checked={settings.AdvancedTranslationEnabled} onChange={(checked) => handleSettingChange('AdvancedTranslationEnabled', checked)} />
                            <div className="ai-setting-button"
                                onClick={() => showModal('Translation', {url, aiName: 'Advanced translation with A/B testing'})}
                            >
                                <SettingOutlined /> Settings
                            </div>
                            <div className="ai-variant-button"
                                onClick={onSwitchToVariantEdit}
                            >
                                <EditOutlined /> AI Variants
                            </div>


                            
                            <Link 
        to="/welcome/image-upload" 
        className="ai-setting-button"
        style={{ marginLeft: '8px' }}
    >
        <UploadOutlined /> Upload Translated images
    </Link>


    <a 
        href="https://seatext.com/best-website-translation-and-localization-services" 
        target="_blank" 
        rel="noopener noreferrer" 
        className="ai-setting-button"
    >
        <InfoCircleOutlined /> Learn more about this model
    </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div className="div-33">
    <div className="column ai-container">
      <div className="ai-choice-container">
        <div className="div-4"   >
          <Tooltip title="You can use this model only if you are on a paid plan or have a positive balance">
            <div className="premium-badge"> <UpCircleOutlined />
              Premium AI model
            </div>
          </Tooltip>
          <div className="ai-card-content">
            <div className="lottie-container">
              <LottieAnimation
                animationPath="https://lottie.host/a57fc31f-6207-4375-859c-22347151cdab/Cd2HOPFeC3.json"
                width={200}
                height={200}
              />
            </div>
            <div className="ai-text-content">
              <div className="ai-title-container">
                <h2 className="ai-title">Beta AI model for mobile adaptation</h2>
              </div>
              <div className="ai-description">
                This AI model boosts mobile conversions by automatically optimizing website content for smaller screens. It transforms desktop content into shorter, more impactful mobile versions, ensuring easy readability and engagement. The process also includes automated A/B testing to find the best-performing version, further increasing conversions. Since 90% of websites use the same content for both mobile and desktop, making mobile sites harder to read, this model trims text where possible and shows optimized versions to mobile users automatically.
              </div>
              
              <div className="ai-card-actions">
                <div className="flex items-center gap-2">
                  <Switch 
                    disabled
                    checked={false}
                    className="opacity-50 cursor-not-allowed"
                  />
                  <span className="text-sm text-gray-500 flex items-center gap-1">
                    <LockOutlined /> Closed Beta Only
                  </span>
                </div>
                <div className="ai-setting-button opacity-50 cursor-not-allowed">
                  <SettingOutlined /> Settings
                </div>
                <div className="ai-variant-button opacity-50 cursor-not-allowed">
                  <EditOutlined /> AI Variants
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div className="column ai-container">
        <div className="ai-choice-container">
            <div className="div-4"   >
            <Tooltip title="You can use this model only if you are on a paid plan or have a positive balance">
                <div className="premium-badge"> <UpCircleOutlined />
                    Premium AI model
                </div>
            </Tooltip>
                <div className="ai-card-content">
                    <div className="lottie-container">
                        <LottieAnimation
                            animationPath="https://lottie.host/5702af4f-b49e-4172-b263-3c7054c6bcf8/o5wLdGj0Tn.json"
                            width={200}
                            height={200}
                        />
                    </div>
                    <div className="ai-text-content">
    <div className="ai-title-container">
        <h2 className="ai-title">AI personalizes your website using data from Clay.com, LinkedIn, CRM for high ticket sales</h2>
    </div>
    <div className="ai-description">
    Landing pages are typically designed for a broader audience, but when you're reaching out to specific prospects—whether via LinkedIn messages or cold emails—personalization is key. However, manually tailoring these pages for each prospect is time-consuming and impractical. Now, our AI solves this problem by automatically creating thousands of personalized landing page experiences in minutes, each tailored to address the unique pain points of your individual prospects.

By leveraging your existing data from Clay.com, CRM systems, and email interactions, our solution automatically generates a personalized experience that speaks directly to each prospect’s business needs, positioning your solution as the perfect fit. This enables you to send highly targeted links to your prospects, dramatically improving outreach and conversion rates for your ABM campaigns—without the need to manually personalize each page.


<div className="feature-enabled-text">
    <span className="checkmark-icon">✔</span> {/* Replace with an icon if needed */}
    Advanced AI Filtering Enabled
</div>
<div className="feature-enabled-text">
    <span className="checkmark-icon">✔</span> {/* Replace with an icon if needed */}
    Advanced Copywriting Enabled
</div>



    </div>
 
    <div className="ai-card-actions">
        <Switch checked={settings.LandingPagesEnabled} onChange={(checked) => handleSettingChange('LandingpagesEnabled', checked)} />
        <div className="ai-setting-button" onClick={() => showModal('AISalesPersonalisation', {url, aiName: 'AISalesPersonalisation'})}>
            <SettingOutlined /> Settings
        </div>


        <a 
        href="https://seatext.com/seatext-ai-personalization" 
        target="_blank" 
        rel="noopener noreferrer" 
        className="ai-setting-button"
    >
        <InfoCircleOutlined /> Learn more about this model
    </a>
                           
                        </div>
                    </div>
                </div>






            </div>
        </div>
    </div>


    <div className="column ai-container">
                    <div className="ai-choice-container">
                        <div className="div-4"   >
                            <Tooltip title="You can use this model only if you are on a paid plan or have a positive balance">
                                <div className="premium-badge"> <UpCircleOutlined />
                                    Premium AI model
                                </div>
                            </Tooltip>
                            <div className="ai-card-content">
                                <div className="lottie-container">
                                    <LottieAnimation
                                        animationPath="https://login.seatext.com/google.json"
                                        width={200}
                                        height={300}
                                    />
                                </div>
                                <div className="ai-text-content">
                                    <div className="ai-title-container">
                                        <h2 className="ai-title">AI rewrites your page for each Google Ads Keyword: +35% conversion rate</h2>
                                    </div>
                                    <div className="ai-description">
                                    Our system automatically rewrites your page in real time for every keyword in your Google Ads campaign, ensuring your text content aligns perfectly with search intent. Each keyword gets its own dedicated tag with your current URL. Seatext AI completely optimizes your page’s messaging for better conversions, enhancing it without altering the design to deliver hyper-targeted experiences that resonate with your audience. 1 keyword = 1 unique landing page text.

                                    <div className="feature-enabled-text">
    <span className="checkmark-icon">✔</span> {/* Replace with an icon if needed */}
    Advanced AI Filtering Enabled
</div>
<div className="feature-enabled-text">
    <span className="checkmark-icon">✔</span> {/* Replace with an icon if needed */}
    Advanced Copywriting Enabled
</div>

                                    </div>
                                    <div className="ai-card-actions">
                                        <Switch checked={settings.LandingPagesEnabled} onChange={(checked) => handleSettingChange('LandingPagesEnabled', checked)} />
                                        <div className="ai-setting-button" onClick={() => showModal('AILandingPageOptimization', {url, aiName: 'AILandingPageOptimization'})}>
                                            <SettingOutlined /> Settings
                                        </div>
                                        <a 
                                            href="https://seatext.com/ai-google-ads-landing-page-optimization" 
                                            target="_blank" 
                                            rel="noopener noreferrer" 
                                            className="ai-setting-button"
                                        >
                                            <InfoCircleOutlined /> Learn more about this model
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="column ai-container">
  <div className="ai-choice-container">
    <div className="div-4">
      <Tooltip title="You can use this model only if you are on a paid plan or have a positive balance">
        <div className="premium-badge">
          <UpCircleOutlined />
          Premium AI model
        </div>
      </Tooltip>
      <div className="ai-card-content">
        <div className="lottie-container">
          <LottieAnimation
            animationPath="https://lottie.host/c56a9c7a-f54c-44e4-899c-5b0a5f9bd9a6/xWwlYsrHod.json"
            width={250}
            height={200}
          />
        </div>
        <div className="ai-text-content">
          <div className="ai-title-container">
          <h2 className="ai-title">
  AI rewrites pages based on visitor origin or redirects to optimal page
</h2>
</div>
<div className="ai-description">
  Transform your website's performance with AI-driven content personalization that automatically adapts landing pages to match visitor interests based on their origin - whether they arrive from product articles, category-specific reviews, or other referral sources. Our system analyzes traffic sources in real-time, dynamically optimizing content to deliver hyper-relevant experiences that boost conversions. When appropriate, the AI can bypass content adaptation entirely and intelligently route visitors directly to specific product pages, ensuring visitors always take the most efficient path to engagement. This dual approach of instant content customization or smart redirection creates streamlined user journeys that maximize both engagement and conversion opportunities.

            <div className="feature-enabled-text">
              <span className="checkmark-icon">✔</span> Rewrite content or redirect visitor to the best page
            </div>
            <div className="feature-enabled-text">
              <span className="checkmark-icon">✔</span> AI visits source page to get insights for rewritting
            </div>
          </div>
          <div className="ai-card-actions">
            <Switch
              checked={settings.LandingPagesEnabled}
              onChange={(checked) =>
                handleSettingChange('LandingPagesEnabled', checked)
              }
            />
            <div
              className="ai-setting-button"
              onClick={() =>
                showModal('AITrafficRouting', {
                  url,
                  aiName: 'AITrafficRouting',
                })
              }
            >
              <SettingOutlined /> Settings
            </div>
            <a
              href="https://seatext.com/ai-visitor-origin-dynamic-rewrite"
              target="_blank"
              rel="noopener noreferrer"
              className="ai-setting-button"
            >
              <InfoCircleOutlined /> Learn more about this model
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

             
 
                    {/* New AI Model: Scroll Slowdown for Conversion Optimization */}
                    <div className="column ai-container">
                        <div className="ai-choice-container">
                            <div className="div-4"   >

                            <div className="free-badge">
                                    <span>Free Model</span>
                                </div>



                                <div className="ai-card-content">
                                    <div className="lottie-container">
                                        <LottieAnimation
                                            animationPath="https://lottie.host/fee81eee-bd26-4cf3-a488-6b01d5f78a26/0lnAHqjPeD.json" // Replace with your Lottie animation URL
                                            width={300}
                                            height={250}
                                        />
                                    </div>
                                    <div className="ai-text-content">
                                        <div className="ai-title-container">
                                            <h2 className="ai-title">AI improve conversion rate by slowing down scrolling speed</h2>
                                        </div>
                                        <div className="ai-description">
                                        Marketers often analyze user behavior through website recordings, only to notice a common, frustrating problem: visitors scroll too quickly past critical elements like call-to-action buttons, key headlines, or pricing details. This rapid scrolling prevents users from fully engaging with the content you’ve carefully optimized, leading to missed opportunities and lower conversion rates.

Our innovative AI tackles this issue head-on by dynamically adjusting scrolling speed at just the right moments. It intelligently detects essential elements on your page and subtly encourages users to linger, ensuring they absorb the information that drives action.

By addressing this scrolling behavior, you’ll not only improve content engagement but also lower bounce rates, boost conversions, and create a more meaningful user experience. See your visitors interact with your site the way you intended—with smarter, more deliberate scrolling.


</div>
                                        <div className="ai-card-actions">
                                        
                                                <Switch checked={settings.AiSlowDown} onChange={(checked) => handleSettingChange('AiSlowDown', checked)} />
                                            
                                            
                                            <a
                                                href="https://seatext.com/ai-scroll-slowdown-conversion-optimization"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="ai-setting-button"
                                            >
                                                <InfoCircleOutlined /> Learn more about this model
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>














</div>
</div>

<Modal
    title={`${currentModal.context.aiName} Settings`}
    visible={currentModal.visible}
    onCancel={closeModal}
    footer={null}
    width="80%"
    style={{
        position: 'fixed',
        top: 0,
        right: 0,
        bottom: 0,
        margin: 0,
        padding: 0,
        maxWidth: '80%',
        height: '100%',
    }}
    bodyStyle={{ 
        height: 'calc(100vh - 108px)', // Full height minus header and padding
        overflowY: 'auto', // Enable vertical scrolling
        overflowX: 'hidden', // Disable horizontal scrolling
        padding: '24px',
    }}
    wrapClassName="right-side-modal"
    maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.45)' }}
>
    <div style={{ width: '100%', height: '100%', overflowY: 'auto', overflowX: 'hidden' }}>
        {currentModal.type === 'Product Description Optimization' && <Etrade context={currentModal.context} />}
        {currentModal.type === 'AIABTesting' && <SettingsPage2 context={currentModal.context} />}
        {currentModal.type === 'Translation' && <TranslationSettingsPage context={currentModal.context} />}
        {currentModal.type === 'Mobile' && <Mobile context={currentModal.context} />}
        {currentModal.type === 'Landingpages' && <Landingpages context={currentModal.context} />}
        {currentModal.type === 'AISalesPersonalisation' && <AISalesPersonalisation context={currentModal.context} />}
        {currentModal.type === 'AILandingPageOptimization' && <AILandingPageOptimization context={currentModal.context} />}
        {currentModal.type === 'AITrafficRouting' && <AITrafficRouting context={currentModal.context} />}
    </div>
</Modal>
        </Card>
    );
};

export default URLDataCard;