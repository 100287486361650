import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import { fetchUsageStats } from './utils'; // Adjust the import path as necessary
import { Typography, Card, Slider, Button, Alert, Table, Tooltip, Row, Col } from 'antd';
import { InfoCircleOutlined, CheckCircleFilled, StarFilled, RocketFilled, ThunderboltFilled, EyeFilled, FileTextFilled, TeamOutlined, GlobalOutlined } from '@ant-design/icons';
import ShareSection from './ShareSection.js';
import PaymentSection from './TopUpBalance'; // Adjust the import path as necessary

const { Title, Text } = Typography;

export default function BillingAndPayments() {
  const [currentViewCount, setCurrentViewCount] = useState(0);
  const [currentWordCount, setCurrentWordCount] = useState(0);
  const [viewLimit, setViewLimit] = useState(null);
  const [wordLimit, setWordLimit] = useState(null);
  const [currentForecast, setCurrentForecast] = useState(0);
  const [monthlyLimit, setMonthlyLimit] = useState(0);
  const [usageStats, setUsageStats] = useState({ ViewsTotal: 0, PageViewsLimit: 0, WordsUsedTotal: 0, TokensLimit: 0, Bonus: 0, ClientUsage: [] });
  const [balance, setBalance] = useState(0);

  // Define your brand colors
  const brandLightBlue = '#4096ff';
  const brandDeepBlue = '#1890ff';

  // Custom checkmark component using your brand color
  const BlueCheckmark = () => (
    <span style={{ color: brandDeepBlue, marginRight: '8px' }}>✓</span>
  );



  

  useEffect(() => {
    const fetchStats = async () => {
      const result = await fetchUsageStats();
      if (result && result.success) {
        setUsageStats({
          ViewsTotal: result.data.ViewsTotal,
          WordsUsedTotal: result.data.WordsUsedTotal,
          balance: result.data.balance,
          Bonus: result.data.Bonus || 0,
          ClientUsage: result.data.ClientUsage || []
        });
        setCurrentViewCount(result.data.ViewsTotal);
        setCurrentWordCount(result.data.WordsUsedTotal);
        setBalance(result.data.RealBalance || 0);

        const currentViewsCost = Math.max(result.data.ViewsTotal, 0) / 1000 * 0.1;
        const currentWordsCost = Math.max(result.data.WordsUsedTotal, 0) / 1000 * 2;
        setCurrentForecast(currentViewsCost + currentWordsCost);

        setViewLimit(result.data.PageViewsLimit || Math.round(result.data.ViewsTotal * 1.2));
        setWordLimit(result.data.TokensLimit || Math.round(result.data.WordsUsedTotal * 1.2));
      } else {
        console.error('Failed to fetch usage stats', result ? result.error : 'Unknown error');
      }
    };
    fetchStats();
  }, []);

  useEffect(() => {
    const viewsCost = viewLimit ? Math.max(viewLimit, 0) / 1000 * 0.1 : 0;
    const wordsCost = wordLimit ? Math.max(wordLimit, 0) / 1000 * 2 : 0;
    setMonthlyLimit(viewsCost + wordsCost);
  }, [viewLimit, wordLimit]);

  const createSubscriptionLink = async (planId) => {
    const email = Cookies.get('email');
    if (!email) {
      console.error('Email not found in cookies.');
      return;
    }
    const requestBody = { email, planId };

    const response = await fetch(`https://api.seatext.com:443/client/subscription`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(requestBody),
    });

    if (!response.ok) {
      console.error(`Error ${response.status}: ${response.statusText}`);
      return;
    }

    try {
      const data = await response.json();
      window.location.href = data.url;
    } catch (error) {
      console.error('Error parsing JSON:', error);
    }
  };

  const setLimits = async () => {
    console.log('Setting limits:', { viewLimit, wordLimit });
    // TODO: Implement API call to set limits
  };

  const turnOffLimits = () => {
    setViewLimit(Math.round(currentViewCount * 1.4));
    setWordLimit(Math.round(currentWordCount * 1.4));
    console.log('Limits turned off');
    // TODO: Implement API call to turn off limits
  };

  const formatNumber = (num) => {
    if (num >= 1000000) return (num / 1000000).toFixed(1) + 'M';
    if (num >= 1000) return (num / 1000).toFixed(1) + 'K';
    return num.toString();
  };

  const calculateRange = (currentValue) => {
    const min = Math.max(Math.round(currentValue * 0.3), 1);
    const max = Math.round(currentValue * 3);
    return [min, max];
  };

  const createMarks = (min, max, current) => {
    const recommendedLimit = Math.round(current * 1.4);
    const marks = {};

    // Add min and max
    marks[min] = formatNumber(min);
    marks[max] = formatNumber(max);

    // Add current usage and recommended limit
    marks[current] = {
      style: { color: 'blue' },
      label: <Tooltip title="Current Usage"><strong>{formatNumber(current)} - Current usage</strong></Tooltip>
    };
    marks[recommendedLimit] = {
      style: { color: 'red' },
      label: <Tooltip title="Recommended Limit"><strong>{formatNumber(recommendedLimit)} - Recommended Limit</strong></Tooltip>
    };

    // Calculate potential mark positions
    const range = max - min;
    const potentialMarks = [
      min + range * 0.25,
      min + range * 0.5,
      min + range * 0.75,
    ].map(Math.round);

    // Filter and add marks that are sufficiently far from existing marks
    potentialMarks.forEach(mark => {
      const isFarEnough = Object.keys(marks).every(existingMark =>
        Math.abs(mark - existingMark) / range > 0.15
      );

      if (isFarEnough) {
        marks[mark] = formatNumber(mark);
      }
    });

    return marks;
  };

  const [viewMin, viewMax] = calculateRange(currentViewCount);
  const [wordMin, wordMax] = calculateRange(currentWordCount);

  const viewMarks = createMarks(viewMin, viewMax, currentViewCount);
  const wordMarks = createMarks(wordMin, wordMax, currentWordCount);

  const parsedUsageData = usageStats.ClientUsage.map((usage, index, array) => {
    const parts = usage.split(' - ');
    if (parts.length !== 2) return null;

    const [date, details] = parts;
    const detailsParts = details.split(', ');
    if (detailsParts.length !== 3) return null;

    const [words, views, balance] = detailsParts;
    const currentBalance = parseFloat(balance.split('$')[1]) || 0;

    let dailyCost = 'N/A';
    if (index < array.length - 1) {
      const nextUsage = array[index + 1];
      const nextBalancePart = nextUsage.split(' - ')[1].split(', ')[2];
      const nextBalance = parseFloat(nextBalancePart.split('$')[1]) || 0;

      // Calculate the daily cost
      let calculatedCost = Math.abs(nextBalance - currentBalance);

      // If the cost is unreasonably high (over $300), set it to a more reasonable value
      if (calculatedCost > 300) {
        // Calculate a more reasonable cost based on usage
        const wordsCount = parseInt(words.split(': ')[1]) || 0;
        const viewsCount = parseInt(views.split(': ')[1]) || 0;

        // Use the standard pricing: $2 per 1000 words, $0.1 per 1000 views
        calculatedCost = (wordsCount / 1000 * 2) + (viewsCount / 1000 * 0.1);
      }

      dailyCost = calculatedCost.toFixed(2);
    }

    return {
      date,
      words: parseInt(words.split(': ')[1]) || 0,
      views: parseInt(views.split(': ')[1]) || 0,
      balance: currentBalance,
      dailyCost
    };
  }).filter(item => item !== null);

  const columns = [
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
    },
    {
      title: 'AI Used Words',
      dataIndex: 'words',
      key: 'words',
    },
    {
      title: 'AI Managed Views',
      dataIndex: 'views',
      key: 'views',
    },
    {
      title: 'Balance at End of Day',
      dataIndex: 'balance',
      key: 'balance',
      render: (balance) => `$${balance.toFixed(2)}`
    },
    {
      title: 'Daily Cost',
      dataIndex: 'dailyCost',
      key: 'dailyCost',
      render: (cost) => `$${cost}`
    },
  ];
  const premiumBenefits = [
    { 
      icon: <StarFilled style={{ color: brandDeepBlue, fontSize: '24px' }} />, 
      title: 'Premium AI Models', 
      description: 'Gain access to all of our premium models, fine-tuned for specific tasks such as boosting online store conversion rates, advanced translations with A/B testing, and more.' 
    },
    { 
      icon: <RocketFilled style={{ color: brandDeepBlue, fontSize: '24px' }} />, 
      title: 'Instant Content Delivery', 
      description: 'Receive new content and translations within minutes instead of waiting up to 30 days. Your requests are processed with priority.' 
    },
    { 
      icon: <ThunderboltFilled style={{ color: brandDeepBlue, fontSize: '24px' }} />, 
      title: 'AI Content Filtering', 
      description: 'Advanced AI agents minimize content review time by identifying and correcting mistakes, ensuring the text aligns with your industry standards, landing page goals, and brand style. 99% of the variants are ready to publish without manual review.' 
    },
    { 
      icon: <TeamOutlined style={{ color: brandDeepBlue, fontSize: '24px' }} />, 
      title: 'Dedicated Support', 
      description: 'Receive direct assistance from our conversion rate optimization team for effective SEATEXT AI implementation.' 
    },
    { 
      icon: <EyeFilled style={{ color: brandDeepBlue, fontSize: '24px' }} />, 
      title: 'Continuous Optimization', 
      description: 'AI continuously generates and tests new variants, learning from results to improve conversion rates nonstop.' 
    },
    { 
      icon: <FileTextFilled style={{ color: brandDeepBlue, fontSize: '24px' }} />, 
      title: 'AI Vision Model', 
      description: 'Our advanced AI visually analyzes your website, understanding the context and significance of each element, such as menus, CTAs, and more.' 
    },
    { 
      icon: <GlobalOutlined style={{ color: brandDeepBlue, fontSize: '24px' }} />, 
      title: 'Multi-Language Optimization', 
      description: 'When our model identifies highly effective wording in one language, it automatically applies similar optimizations across all supported languages.' 
    },
    {
      icon: <InfoCircleOutlined style={{ color: brandDeepBlue, fontSize: '24px' }} />,
      title: 'Industry Best Selling Optimizations',
      description: 'When SEATEXT creates new text, it pays attention to what worked and what didn’t on similar websites from the same industry and uses that optimization for your website.'
    },
  ];
  
  return (
    <div style={{ maxWidth: 1600, margin: '0 auto' }}>
      {/* Free Plan Section */}
      <Card
        className="plan-card"
        style={{
          marginBottom: '40px',
          position: 'relative',
          overflow: 'visible',
          backgroundColor: 'white',
          boxShadow: '0 10px 15px -3px rgba(0, 0, 0, 0.1)',
          borderRadius: '12px'
        }}
        bodyStyle={{ paddingTop: '80px' }}
      >
        <div style={{
          position: 'absolute',
          top: '-20px',
          left: '20px',
          transform: 'none',
          minWidth: '550px',
          backgroundColor: '#4096ff',
          color: 'white',
          padding: '15px 25px',
          borderRadius: '8px',
          textAlign: 'left',
          boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
        }}>
          <Title level={2} style={{ color: 'white', margin: 0 }}>FREE PLAN</Title>
          <Text style={{ color: 'white', fontSize: '16px' }}>Start using AI without any costs</Text>
        </div>

        <Row gutter={24}>
          <Col span={16}>
            <div style={{ marginTop: '40px' }}>
              <Title level={4} style={{ marginBottom: '20px' }}>
                <BlueCheckmark /> Free to use, without limits. Apply any FREE SEATEXT AI models to unlimited pages, access unlimited translations in 125 languages, and enjoy unlimited AI-managed page views.
              </Title>

              <Text style={{ fontSize: '16px' }}>
                <div style={{ display: 'flex', alignItems: 'flex-start', marginBottom: '8px' }}>
                  <BlueCheckmark />
                  <span>Start immediately - no credit card required</span>
                </div>
            
                <div style={{ display: 'flex', alignItems: 'flex-start', marginBottom: '8px' }}>
                  <BlueCheckmark />
                  <span>Access to Free models even when your balance is below $0</span>
                </div>
                <div style={{ display: 'flex', alignItems: 'flex-start', marginBottom: '8px' }}>
                  <BlueCheckmark />
                  <span>NO ADS, NO data selling - we respect your visitors' privacy</span>
                </div>
                <div style={{ display: 'flex', alignItems: 'flex-start', marginBottom: '8px' }}>
                  <BlueCheckmark />
                  <span>No hidden SEO backlinks - your website stays 100% yours</span>
                </div>
                <div style={{ display: 'flex', alignItems: 'flex-start', marginBottom: '8px' }}>
                  <BlueCheckmark />
                  <span>SMART GDPR COOKIES feature included with unlimited usage</span>
                </div>
                <div style={{ display: 'flex', alignItems: 'flex-start', marginBottom: '8px' }}>
                  <BlueCheckmark />
                  <span>AI improves conversion rates by slowing down scrolling speed, included with unlimited usage</span>
                </div>

                <div style={{ display: 'flex', alignItems: 'flex-start', marginBottom: '8px' }}>
                  <BlueCheckmark />
                  <span>Free translation of your website (Basic AI model without A/B testing and AI agents that ensure error-free translation) included without any limits.</span>
                </div>
                
              </Text>
            </div>
          </Col>
          <Col span={8}>
            <ShareSection />
          </Col>
        </Row>
      </Card>

      {/* Premium Plan Section */}
      <Card
  className="plan-card"
  style={{
    marginBottom: '40px',
    position: 'relative',
    overflow: 'visible',
    backgroundColor: '#F8FAFC',
    boxShadow: '0 20px 25px -5px rgba(0, 0, 0, 0.1)',
    borderRadius: '12px',
    border: '2px solid #e6f4ff'
  }}
  bodyStyle={{ paddingTop: '80px' }}
>
  <div style={{
    position: 'absolute',
    top: '-20px',
    left: '20px',
    transform: 'none',
    minWidth: '550px',
    backgroundColor: '#4096ff',
    color: 'white',
    padding: '15px 25px',
    borderRadius: '8px',
    textAlign: 'left',
    boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
  }}>
    <Title level={2} style={{ color: 'white', margin: 0 }}>PREMIUM PLAN</Title>
    <Text style={{ color: 'white', fontSize: '16px' }}>Get significantly better results with premium models</Text>
  </div>

  <div style={{ marginTop: '40px' }}>
    <Title level={4} style={{ marginBottom: '0px' }}>
      <BlueCheckmark /> Experience an average <span style={{ color: '#4096ff', fontWeight: 500 }}>+35%</span> increase in conversion rates with our advanced AI models. Our premium features deliver superior results through personalized optimization and advanced content generation capabilities.
    </Title>
  </div>

  <div style={{ marginTop: '0px' }}>
    <ul style={{ margin: '20px 0', fontSize: '16px' }}>
      <li><span style={{ color: '#4096ff', fontWeight: 500 }}>$0.1</span> per <span style={{ color: '#4096ff', fontWeight: 500 }}>1,000</span> AI-managed views</li>
      <li><span style={{ color: '#4096ff', fontWeight: 500 }}>$2</span> per <span style={{ color: '#4096ff', fontWeight: 500 }}>1,000</span> AI-generated words</li>
    </ul>
    <Text style={{ display: 'block', marginTop: '10px', marginBottom: '10px' }}>
      You can always set a spending limit per month, and content AI generation will be stopped above limit.
    </Text>

    <Row gutter={[16, 16]} style={{ marginTop: '20px' }}>
  {premiumBenefits.map((benefit, index) => (
    <Col span={12} key={index}>
      <Card
        style={{ 
          borderRadius: '8px', 
          boxShadow: '0 4px 8px rgba(0,0,0,0.1)', 
          height: '100%', // Ensure all cards have the same height
          display: 'flex', 
          flexDirection: 'column' 
        }}
        bodyStyle={{ 
          padding: '16px', 
          flex: 1, // Make the body stretch to fill the card
          display: 'flex', 
          flexDirection: 'column', 
          justifyContent: 'space-between' // Space out content evenly
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
          {benefit.icon}
          <Text strong style={{ marginLeft: '8px', fontSize: '16px' }}>{benefit.title}</Text>
        </div>
        <Text>{benefit.description}</Text>
      </Card>
    </Col>
  ))}
</Row>
  </div>
  <PaymentSection />
</Card>





      {/* Balance and Usage Section */}
      {balance < 0 && (
        <Alert
          message="Warning"
          description="Your balance is below $0. While free models are still active, Premium models cannot generate new AI content but will continue to display previously created content. If you recently added your card and still see your balance negative wait few hours."
          type="warning"
          showIcon
          style={{ marginBottom: 20 }}
        />
      )}

      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 30 }}>
        <Card style={{ width: '32%' }}>
          <Title level={3}>Balance</Title>
          <Text type="secondary">Current balance in your account</Text>
          <Title level={3} style={{ color: balance < 0 ? 'red' : 'rgba(27,31,38,0.72)' }}>${balance.toFixed(2)}</Title>
          {usageStats.Bonus > 0 && (
            <div style={{ marginTop: '10px' }}>
              <Text type="secondary">Bonus</Text>
              <Title level={4} style={{ color: 'green', margin: '0' }}>${usageStats.Bonus.toFixed(2)}</Title>
            </div>
          )}
        </Card>
        <Card style={{ width: '32%' }}>
          <Title level={3}>Current Monthly Usage</Title>
          <Text type="secondary">Based on your current usage</Text>
          <div style={{ marginTop: '10px' }}>
            <Text>AI Words: {formatNumber(currentWordCount)}</Text>
          </div>
          <div>
            <Text>AI Views: {formatNumber(currentViewCount)}</Text>
          </div>
          <Title level={4} style={{ marginTop: '10px', color: 'rgba(27,31,38,0.72)' }}>
            ${currentForecast.toFixed(2)}/mo
          </Title>
        </Card>
        <Card style={{ width: '32%' }}>
          <Title level={3}>Monthly Limit</Title>
          <Text type="secondary">Based on your set limits</Text>
          <Title level={3} style={{ color: 'rgba(27,31,38,0.72)' }}>${monthlyLimit.toFixed(2)}</Title>
        </Card>
      </div>

      {/* Set Monthly Limits Section */}
      <Card title="Set Monthly Limits" style={{ marginBottom: 30 }}>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '100px' }}>
          <div>
            <Text style={{ display: 'block', marginBottom: 40 }}>
              Monthly limit for AI-managed views
            </Text>
            <Slider
              min={viewMin}
              max={viewMax}
              marks={viewMarks}
              tooltipVisible
              onChange={setViewLimit}
              value={viewLimit}
              included={true}
              tipFormatter={value => `Monthly limit: ${formatNumber(value)}`}
            />
          </div>
          <div>
            <Text style={{ display: 'block', marginBottom: 40 }}>
              Monthly limit for AI-rewritten words
            </Text>
            <Slider
              min={wordMin}
              max={wordMax}
              marks={wordMarks}
              tooltipVisible
              onChange={setWordLimit}
              value={wordLimit}
              included={true}
              tipFormatter={value => `Monthly limit: ${formatNumber(value)}`}
            />
          </div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 20 }}>
          <Button
            type="primary"
            size="large"
            onClick={setLimits}
            style={{
              backgroundColor: '#1890ff',
              color: 'white',
              transition: 'box-shadow 0.3s',
            }}
            onMouseEnter={(e) => e.currentTarget.style.boxShadow = '0 0 10px rgba(0, 0, 255, 0.5)'}
            onMouseLeave={(e) => e.currentTarget.style.boxShadow = 'none'}
          >
            Set Limits
          </Button>
          <Button
            size="large"
            onClick={turnOffLimits}
            style={{
              transition: 'box-shadow 0.3s',
            }}
            onMouseEnter={(e) => e.currentTarget.style.boxShadow = '0 0 10px rgba(0, 0, 255, 0.5)'}
            onMouseLeave={(e) => e.currentTarget.style.boxShadow = 'none'}
          >
            Turn Off Limits
          </Button>
        </div>
      </Card>

     
     
      {/* Usage Table Section */}
      {parsedUsageData.length > 0 && (
        <Card title="Your Usage per Day" style={{ marginTop: 30 }}>
          <Table
            dataSource={parsedUsageData}
            columns={columns}
            pagination={false}
            rowKey="date"
          />
        </Card>
      )}
    </div>
  );
}