import React, { useState, useEffect } from 'react';
import { Typography, Card, Button, Input, Form, Table, Switch, Modal, Upload, message, Spin, Tooltip } from 'antd';
import { PlusOutlined, InfoCircleOutlined, EditOutlined, DeleteOutlined, UploadOutlined, DownloadOutlined, RobotOutlined } from '@ant-design/icons';
import Papa from 'papaparse';
import { sendRoutesToServer, fetchRoutesFromServer } from './utils';
import VideoPlayer from './VideoPlayer';

const { Title } = Typography;

// Function for normalizing URLs
const normalizeUrl = (url) => {
  if (!url) return '';
  let normalizedUrl = url.trim();
  if (!normalizedUrl.match(/^https?:\/\//i)) {
    normalizedUrl = `https://${normalizedUrl}`;
  }
  return normalizedUrl.replace(/\/+$/, '');
};

// Function to validate a URL
const isValidUrl = (url) => {
  try {
    new URL(url.startsWith('http') ? url : 'https://' + url);
    return true;
  } catch (error) {
    return false;
  }
};

// Helper function to get the hostname with error handling
const getHostname = (url) => {
  try {
    return new URL(url).hostname;
  } catch (error) {
    return url;
  }
};

function AITrafficRouting() {
    const [routes, setRoutes] = useState([]);
    const [editModalVisible, setEditModalVisible] = useState(false);
    const [currentRoute, setCurrentRoute] = useState(null);
    const [addModalVisible, setAddModalVisible] = useState(false);
    const [form] = Form.useForm();
    const [processing, setProcessing] = useState(false);
    const [countdown, setCountdown] = useState(0);
  
    useEffect(() => {
      async function loadRoutes() {
        const routesArray = await fetchRoutesFromServer();
        console.log("Fetched routesArray:", routesArray);
        // Map each route using the server’s capitalized property names.
        const mappedRoutes = routesArray.map(item => ({
          id: item.ID,                            // Unique identifier from the server
          sourceUrl: item.Referrer,               // In your sample, this is "https://login.seatext.com/"
          targetUrl: item.FullURL,                // e.g. "https://login.seatext.com/welcome/ai-mobile"
          redirectUrl: item.RedirectingURL,       // e.g. an empty string or a URL if provided
          aiPrompt: item.ClientAIInstructions || 'No instructions provided',
          enableRewrite: item.AllowAIToRewriteText, // Boolean value
          enableRedirect: item.AllowRedirect        // Boolean value
        }));
        console.log("Mapped Routes:", mappedRoutes);
        setRoutes(mappedRoutes);
      }
      loadRoutes();
    }, []); // Empty dependency array to load routes only once on mount
  

  // Definition of table columns.
  // Column order:
  // 1. Source URL – where the visitor came from;
  // 2. Target URL – the primary landing page;
  // 3. AI Rewrite Instructions – instructions for AI to adapt the landing page;
  // 4. Rewrite Active – toggle to enable AI rewriting;
  // 5. Redirect URL – optional URL for redirection;
  // 6. Redirect Active – toggle to enable redirection;
  // 7. Actions – edit/delete rule.
  const columns = [
    {
      title: 'Source URL',
      dataIndex: 'sourceUrl',
      key: 'sourceUrl',
      render: text => (
        <Tooltip title={text}>
          <span>{text.slice(0, 40)}{text.length > 40 && '...'}</span>
        </Tooltip>
      )
    },
    {
      title: 'Target URL',
      dataIndex: 'targetUrl',
      key: 'targetUrl',
      render: text => {
        const hostname = getHostname(text);
        return <a href={text} target="_blank" rel="noopener noreferrer">{hostname}</a>;
      }
    },
    {
      title: 'AI Rewrite Instructions',
      dataIndex: 'aiPrompt',
      key: 'aiPrompt',
      render: text => (
        <div style={{
          maxHeight: '4em',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          WebkitLineClamp: 3,
          WebkitBoxOrient: 'vertical'
        }}>
          {text}
        </div>
      )
    },
    {
      title: 'Rewrite Active',
      key: 'enableRewrite',
      render: (_, record) => (
        <Tooltip title="Enable AI rewriting">
          <Switch 
            checked={record.enableRewrite}
            onChange={checked => handleToggle(record.id, 'enableRewrite', checked)}
          />
        </Tooltip>
      )
    },
    {
      title: 'Redirect URL',
      dataIndex: 'redirectUrl',
      key: 'redirectUrl',
      render: text => text ? (
        <a href={text} target="_blank" rel="noopener noreferrer">{getHostname(text)}</a>
      ) : '—'
    },
    {
      title: 'Redirect Active',
      key: 'enableRedirect',
      render: (_, record) => (
        <Tooltip title="Enable redirection">
          <Switch
            checked={record.enableRedirect}
            onChange={checked => handleToggle(record.id, 'enableRedirect', checked)}
          />
        </Tooltip>
      )
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <div style={{ display: 'flex', gap: '8px' }}>
          <Button icon={<EditOutlined />} onClick={() => handleEdit(record)} />
          <Button icon={<DeleteOutlined />} danger onClick={() => handleDelete(record.id)} />
        </div>
      )
    }
  ];

  // CSV file import handling.
  // Expected CSV fields: sourceUrl, targetUrl, redirectUrl, aiPrompt, enableRewrite, enableRedirect.
  const handleCSVUpload = (file) => {
    Papa.parse(file, {
      header: true,
      complete: (results) => {
        const newRoutes = results.data.map(row => ({
          id: Date.now() + Math.random(),
          sourceUrl: normalizeUrl(row.sourceUrl),
          targetUrl: normalizeUrl(row.targetUrl),
          redirectUrl: row.redirectUrl ? normalizeUrl(row.redirectUrl) : '',
          aiPrompt: row.aiPrompt || '',
          enableRewrite: row.enableRewrite === 'true',
          enableRedirect: row.enableRedirect === 'true'
        }));
        setRoutes(prev => [...prev, ...newRoutes]);
        message.success(`Imported ${newRoutes.length} rules`);
      }
    });
  };

  // Export rules to a CSV file.
  const handleCSVExport = () => {
    const csvContent = [
      ['Source URL', 'Target URL', 'Redirect URL', 'AI Rewrite Instructions', 'Enable Rewrite', 'Enable Redirect'],
      ...routes.map(r => [
        r.sourceUrl,
        r.targetUrl,
        r.redirectUrl,
        r.aiPrompt,
        r.enableRewrite,
        r.enableRedirect ? 'true' : 'false'
      ])
    ].map(row => row.join(',')).join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'content-adaptation-rules.csv';
    link.click();
  };

  // Save and deploy all rules to the server.
  const handleSave = async () => {
    setProcessing(true);
    setCountdown(60);
    try {
      await sendRoutesToServer(routes);
      message.success('Rules saved and deployed successfully');
    } catch (error) {
      message.error('Error saving rules: ' + error.message);
    } finally {
      setTimeout(() => {
        setProcessing(false);
        setCountdown(0);
      }, 60000);
    }
  };

  // Toggle a boolean value in the rule.
  const handleToggle = (id, field, value) => {
    setRoutes(prev => prev.map(r => 
      r.id === id ? { ...r, [field]: value } : r
    ));
  };

  const handleEdit = (rule) => {
    setCurrentRoute(rule);
    setEditModalVisible(true);
    form.setFieldsValue(rule);
  };

  const handleDelete = (id) => {
    setRoutes(prev => prev.filter(r => r.id !== id));
    message.success('Rule deleted');
  };

  // Handle form submission with URL normalization.
  const handleFormSubmit = (values) => {
    const normalizedValues = {
      ...values,
      sourceUrl: normalizeUrl(values.sourceUrl),
      targetUrl: normalizeUrl(values.targetUrl),
      redirectUrl: values.redirectUrl ? normalizeUrl(values.redirectUrl) : ''
    };

    if (currentRoute) {
      setRoutes(prev => prev.map(r => r.id === currentRoute.id ? { ...normalizedValues, id: r.id } : r));
    } else {
      setRoutes(prev => [...prev, { ...normalizedValues, id: Date.now() }]);
    }
    setEditModalVisible(false);
    setAddModalVisible(false);
    form.resetFields();
  };

  return (
    <div style={{ width: '100%', margin: '0 auto', padding: '0px' }}>
      {/* Product Description Block */}
      <div data-seatextdont style={{ width: '100%', maxWidth: '100%', overflow: 'hidden' }}>
        <div style={{ width: '100%' }}>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div style={{ width: '100%' }}>
              <Card bordered={false} style={{ width: '100%', margin: '0' }}>
                <div className="ai-card-content" style={{
                  marginBottom: '30px',
                  marginTop: '0px',
                  backgroundColor: '#f8f8f8',
                  minHeight: '350px',
                  padding: '0px',
                  border: '0px solid #A9A9A9',
                  width: '100%',
                  display: 'flex'
                }}>
                  <div style={{ width: '100%', display: 'flex' }}>
                    <div style={{ 
                      flex: '0 0 40%',
                      height: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      paddingRight: '20px'
                    }}>
                      <h2 className="ai-title" style={{ 
                        fontSize: '22px', 
                        marginBottom: '10px', 
                        marginTop: '40px',
                        marginLeft: '10px',
                        width: '100%' 
                      }}>
                       AI dynamically rewrites pages based on visitor origin
                      </h2>
                      <h3 className="ai-subtitle" style={{
                        fontSize: '18px',
                        marginBottom: '30px', 
                        color: '#888', 
                        marginLeft: '10px',
                        width: '100%' 
                      }}>
                        Dynamically rewrite pages or redirect visitors based on their referrer.
                      </h3>
                      <div style={{ 
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}>
                        <div style={{ width: '100%', aspectRatio: '16/9' }}>
                          <VideoPlayer videoFileName="https://example.com/ai-demo.mp4" />
                        </div>
                      </div>
                    </div>
                    <div className="ai-text-content" style={{
                      flex: '0 0 60%',
                      paddingRight: '30px',
                      marginTop: '20px'
                    }}>
                      <div className="ai-description" style={{ 
                        fontSize: '16px', 
                        color: 'rgba(0,0,0,0.65)',
                        marginLeft: '10px',
                      }}>
                      <p>
  Our AI analyzes your visitors' traffic sources - whether they come from product articles or category reviews - and optimizes your landing pages accordingly. The AI can dynamically rewrite content to better match the referring article's theme or redirect visitors to relevant product pages mentioned in the source article.
</p>
<p style={{ marginTop: '15px', marginBottom: '15px' }}>
  Getting started is easy. Follow these steps:
</p>
<ol style={{ 
  paddingLeft: '30px', 
  marginBottom: '15px',
  marginTop: '20px',
  listStyle: 'none',
  counterReset: 'section'
}}>
  <li style={{ marginBottom: '8px', position: 'relative', counterIncrement: 'section' }}>
    <span className="list-number">1.</span>
    <strong>Load current connections:</strong> Press "Export CSV" to get a CSV file containing your website's current traffic sources and modifications.
  </li>
  <li style={{ marginBottom: '8px', position: 'relative', counterIncrement: 'section' }}>
    <span className="list-number">2.</span>
    <strong>Upload your changes:</strong> Prepare a CSV file with the following information:
    • Source URL
    • Target URL
    • AI Rewrite Instructions (optional)
    • Redirect URL (optional)
    • Flags - Should AI rewrite - Should AI redirect (Must be FALSE or TRUE)
    Please use the same CSV format that Seatext provided when you exported it. Please don't delete records from the table. Use Import CSV button to upload CSV with changes.
  </li>
  <li style={{ marginBottom: '8px', position: 'relative', counterIncrement: 'section' }}>
    <span className="list-number">3.</span>
    <strong>Push new rules to server:</strong> Seatext will scan incoming articles and update content based on your new instructions.
  </li>
  <li style={{ marginBottom: '8px', position: 'relative', counterIncrement: 'section' }}>
    <span className="list-number">4.</span>
    <strong>Customize content versions:</strong> Use the preview button to simulate how visitors from different sources will see your website. If you're not satisfied with Seatext's AI-generated content, click the Robot icon to make manual edits.
  </li>
</ol>
                        <style>{`
                          .button-style {
                            display: inline-flex;
                            align-items: center;
                            padding: 2px 8px;
                            margin: 0 4px;
                            background-color: #f0f0f0;
                            border: 1px solid #d9d9d9;
                            border-radius: 4px;
                            color: #000;
                            font-size: 14px;
                            cursor: pointer;
                            transition: all 0.3s;
                          }
                          .button-style:hover {
                            background-color: #1890ff;
                            border-color: #1890ff;
                            color: #fff;
                          }
                          .button-style .anticon {
                            font-size: 12px;
                            margin-right: 4px;
                            position: relative;
                            top: -1px;
                          }
                          .list-number {
                            position: absolute;
                            left: -25px;
                          }
                        `}</style>
                      </div>
                    </div>
                  </div>
                </div>
              </Card>
            </div>
          </div>
        </div>
      </div>

      {/* Content Adaptation Manager */}
      <div style={{ maxWidth: "100%", margin: '0 auto', padding: 24 }}>
        <div style={{ marginBottom: 24, display: 'flex', gap: 16 }}>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => setAddModalVisible(true)}
          >
            New Rule
          </Button>
          <Upload
            accept=".csv"
            beforeUpload={file => {
              handleCSVUpload(file);
              return false;
            }}
          >
            <Button icon={<UploadOutlined />}>Import CSV</Button>
          </Upload>
          <Button icon={<DownloadOutlined />} onClick={handleCSVExport}>
            Export CSV
          </Button>
          <Button
            type="primary"
            onClick={handleSave}
            disabled={processing}
            style={{ marginLeft: 'auto' }}
          >
            {processing ? (
              <Spin size="small" />
            ) : `Deploy Rules${countdown > 0 ? ` (${countdown}s)` : ''}`}
          </Button>
        </div>

        <Table
        columns={[
          {
            title: 'Source URL',
            dataIndex: 'sourceUrl',
            key: 'sourceUrl',
            render: text => (
              <Tooltip title={text}>
                <span>{text.slice(0, 40)}{text.length > 40 && '...'}</span>
              </Tooltip>
            )
          },
          {
            title: 'Target URL',
            dataIndex: 'targetUrl',
            key: 'targetUrl',
            render: text => {
              // Example: display only the hostname
              try {
                const hostname = new URL(text).hostname;
                return <a href={text} target="_blank" rel="noopener noreferrer">{hostname}</a>;
              } catch (error) {
                return text;
              }
            }
          },
          {
            title: 'AI Rewrite Instructions',
            dataIndex: 'aiPrompt',
            key: 'aiPrompt',
            render: text => (
              <div style={{
                maxHeight: '4em',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                WebkitLineClamp: 3,
                WebkitBoxOrient: 'vertical'
              }}>
                {text}
              </div>
            )
          },
          {
            title: 'Rewrite Active',
            key: 'enableRewrite',
            render: (_, record) => (
              <Tooltip title="Enable AI rewriting">
                <Switch 
                  checked={record.enableRewrite}
                  onChange={checked => setRoutes(prev => prev.map(r => 
                    r.id === record.id ? { ...r, enableRewrite: checked } : r
                  ))}
                />
              </Tooltip>
            )
          },
          {
            title: 'Redirect URL',
            dataIndex: 'redirectUrl',
            key: 'redirectUrl',
            render: text => text ? (
              <a href={text} target="_blank" rel="noopener noreferrer">{(() => {
                try {
                  return new URL(text).hostname;
                } catch (err) {
                  return text;
                }
              })()}</a>
            ) : '—'
          },
          {
            title: 'Redirect Active',
            key: 'enableRedirect',
            render: (_, record) => (
              <Tooltip title="Enable redirection">
                <Switch
                  checked={record.enableRedirect}
                  onChange={checked => setRoutes(prev => prev.map(r => 
                    r.id === record.id ? { ...r, enableRedirect: checked } : r
                  ))}
                />
              </Tooltip>
            )
          },
          {
            title: 'Actions',
            key: 'actions',
            render: (_, record) => (
              <div style={{ display: 'flex', gap: '8px' }}>
                <Button icon={<EditOutlined />} onClick={() => {
                  setCurrentRoute(record);
                  setEditModalVisible(true);
                  form.setFieldsValue(record);
                }} />
                <Button icon={<DeleteOutlined />} danger onClick={() => {
                  setRoutes(prev => prev.filter(r => r.id !== record.id));
                  message.success('Rule deleted');
                }} />
              </div>
            )
          }
        ]}
        dataSource={routes}
        rowKey="id"
        bordered
        pagination={{ pageSize: 10 }}
        scroll={{ x: true }}
      />

        <Modal
          title={currentRoute ? "Edit Content Adaptation Rule" : "New Content Adaptation Rule"}
          visible={editModalVisible || addModalVisible}
          onCancel={() => {
            setEditModalVisible(false);
            setAddModalVisible(false);
            form.resetFields();
          }}
          footer={null}
          width={800}
        >
          <Form form={form} layout="vertical" onFinish={handleFormSubmit}>
            <Form.Item
              name="sourceUrl"
              label="Source URL"
              rules={[
                { required: true, message: 'Please enter a Source URL' },
                { validator: (_, v) => isValidUrl(v) ? Promise.resolve() : Promise.reject('Invalid URL') }
              ]}
            >
              <Input placeholder="https://article-site.com/path" />
            </Form.Item>

            <Form.Item
              name="targetUrl"
              label="Target URL"
              rules={[
                { required: true, message: 'Please enter a Target URL' },
                { validator: (_, v) => isValidUrl(v) ? Promise.resolve() : Promise.reject('Invalid URL') }
              ]}
            >
              <Input placeholder="https://yourstore.com/main-page" />
            </Form.Item>

            <Form.Item
              name="aiPrompt"
              label="AI Rewrite Instructions"
              tooltip="How should the landing page be adapted based on the article content?"
            >
              <Input.TextArea
                rows={4}
                placeholder="For example: Emphasize product benefits or highlight seasonal promotions..."
                maxLength={1000}
              />
            </Form.Item>

            <Form.Item
              name="enableRewrite"
              label="Enable AI Rewriting"
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>

            <Form.Item
              name="redirectUrl"
              label="Redirect URL (optional)"
              rules={[{ validator: (_, v) => !v || isValidUrl(v) ? Promise.resolve() : Promise.reject('Invalid URL') }]}
            >
              <Input placeholder="https://yourstore.com/search?query=funny+tshirts" />
            </Form.Item>

            <Form.Item
              name="enableRedirect"
              label="Enable Redirection"
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>

            <Button type="primary" htmlType="submit">
              {currentRoute ? 'Save Changes' : 'Add Rule'}
            </Button>
          </Form>
        </Modal>
      </div>
    </div>
  );
}

export default AITrafficRouting;
